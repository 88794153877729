<template>
    <div>
        走丢啦
    </div>
</template>

<script>
   export default {
    name: 'notFound'
   } 
</script>